import { DateTime } from 'luxon';
import { ApprovalStatusEnum } from '../../../enums/approvalStatusEnum';
import { BaseItem } from '../../base-item';
import { Lookup } from '../../lookup';
import { ApprovalflowGroup } from './approvalflowGroup';

export class ApprovalFlow extends BaseItem {
  ishtarTaskApprovalFlowId?: string;
  parentFlow?: Lookup;
  roundTitle?: string;
  task?: Lookup;
  isApproved?: ApprovalStatusEnum;
  isLocked = false;
  startTime?: Date;
  timingInSeconds?: number;
  comment?: string;
  approvalFlowGroups?: ApprovalflowGroup[];

  constructor(obj: Partial<ApprovalFlow>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.startTime != null && typeof this.startTime == 'string') {
      this.startTime = DateTime.fromFormat(
        obj.startTime.toString(),
        'd/M/yyyy H:m',
        {
          zone: 'utc',
        }
      ).toJSDate();
    }
  }
}
