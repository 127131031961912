import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from 'src/app/shared/error-popup/error-dialog.component';
import { ErrorHandlingService } from './error-handling.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorDialogService {
  constructor(
    private dialog: MatDialog,
    private errorService: ErrorHandlingService
  ) {}

  public openErrorDialog(errors: string[]): void {
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      data: errors,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.errorService.clearErrors();
      this.dialog.closeAll();
    });
  }
}
